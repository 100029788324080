import React from 'react'

import FancyHeading from '../components/FancyHeading'
import BgCircles from '../components/BgCircles'

const HomePage: React.FC = () => {
  return (
    <div className="flex h-screen overflow-x-hidden items-center justify-center bg-gradient-to-t to-blue-dark from-blue-darkest p-10">
      <BgCircles />
      <div className="z-0 w-max p-10 max-h-screen">
        <FancyHeading img="/banner.svg" />

        <div className="mt-5 sm:mt-10 flex flex-col text-center justify-center w-full text-black dark:text-white">
          <h1 className="text-3xl sm:text-5xl">We are <b>Almost</b> ready!</h1>
          <p className="text-base sm:text-xl">Stay tuned for something amazing!</p>
        </div>
      </div>
    </div>
  )
}

export default HomePage
