import React from 'react'

type Props = {
  title?: string,
  img?: string,
}

const FancyHeading: React.FC<Props> = (
  { title, img }
) => {
  const isSvg = img && img.endsWith('.svg');

  return (
    <div className="mb-2 max-w-screen py-4 grid place-items-center gap-4">
      {img && isSvg ? (
          <img src={img} alt="banner" className="max-w-96" />
        ) : (
          <img src={img} alt="banner" className="max-h-64 rounded-lg" />
        )
      }
      {title &&
        <h1 className="text-center text-3xl sm:text-5xl font-bold text-gray-900 dark:text-white leading-tight">{title}</h1>
      }
    </div>
  )
}

export default FancyHeading
